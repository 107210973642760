@use "@porsche-design-system/components-react/styles" as *;

.need-help-container {
  background-color: $pds-theme-light-background-surface;
  padding: $pds-spacing-static-large $pds-spacing-static-medium;

  @include pds-media-query-min('s') {
    margin-top: 80px;
  }

  @include pds-media-query-min-max('base', 'm') {
    margin-top: 24px;
  }

  .need-help-text {
    margin-top: $pds-spacing-static-small;
    margin-bottom: $pds-spacing-static-large;
  }

  .element {
    margin-bottom: $pds-spacing-static-small;
  }

  .elements-container {
    display: flex;
    flex-direction: column;
    gap: $pds-spacing-static-small;
  }
}
