@use "@porsche-design-system/components-react/styles" as *;

.centered-flex {
  display: flex;
  align-items: center;


}

.status-label {
  margin-left: $pds-spacing-static-large;
}

.child-component {
  margin-top: $pds-spacing-static-medium;
}

.dot {
  min-height: 10px;
  min-width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 19px;
  margin-left: 14px;

  &.red {
    background-color: $pds-theme-light-notification-error;
  }

  &.yellow {
    background-color: $pds-theme-light-notification-warning;
  }
}

.registration-state-flex {
  display: flex;
  flex-direction: column;
}
