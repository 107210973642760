@use "@porsche-design-system/components-react/styles" as *;

.access {
  margin-left: $pds-spacing-static-medium;
}

.registration-heading {
  @include pds-media-query-min-max('base', 's') {
    margin-bottom: $pds-spacing-static-small;
  }
}

.data-connection-disabled-heading {
  margin-bottom: $pds-spacing-static-medium;
}

.product-details-component {
  @include pds-media-query-min-max('base', 's') {
    margin-top: 56px;
  }
  @include pds-media-query-min('s') {
    margin-top: 120px;
  }
}
