@use "@porsche-design-system/components-react/styles" as *;

.suspension-toggle {
  display: flex;
  align-items: center;
}

.mno-info {
  align-self: start;
}

.mno-logo-container {
  display: flex;
  flex-direction: column;

  @include pds-media-query-min-max('base', 's') {
    margin-top: $pds-spacing-static-medium;
  }

  @include pds-media-query-min('s') {
    text-align: right;
  }

  .mno-logo {
    max-height: 5rem;
    @include pds-media-query-min-max('base', 's') {
      max-width: 33%;
    }
    @include pds-media-query-min-max('s', 'l') {
      max-width: 45%;
    }
    @include pds-media-query-min('l') {
      max-width: 38%;
    }
  }
}

.mno-text {
  margin-bottom: $pds-spacing-static-small;
}

.top-bar-description {
  margin-top: 0.5rem;
  @include pds-media-query-min-max('base', 's') {
    display: none;
  }
}

.content {
  @include pds-media-query-min-max('base', 'm') {
    margin-bottom: $pds-spacing-static-large;
  }
}
