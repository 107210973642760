.data-volume-gauge {
  display: flex;
  justify-content: space-between;

  .starting-balance {
    align-self: flex-end;
  }

  .gauge-flex {
    display: flex;
  }
}