@use "@porsche-design-system/components-react/styles" as *;
@use "../../../utils/scss/color" as *;

.progress-bar {
  margin-top: 0.8rem;
  margin-bottom: 1rem;
  background-color: $pds-theme-light-contrast-low;
  height: 6px;

  span {
    position: relative;
    display: block;
    background-color: $p-color-brand;
    height: 6px;
  }
}
